<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Choices from "choices.js";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import AgentAutoComplete from '@/components/agent-auto-complete-input'
import AddressAutoComplete from '@/components/address-auto-complete-input'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import CurrencyInput from '@/components/currency-input'

import {
  required,email
} from "vuelidate/lib/validators";

import { search_lawyer, postal_code_query } from '@/api/misc'
import { getDealBasicApi } from '@/api/deal/basic'
import {getDealApi} from '@/api/deal'
import {financial} from '@/api/misc'

//import CurrencyInput from '@/components/currency-input'


/**
 * Setup PM  component
 */
export default {
  page: {
    title: "Deal",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations() {

    if (this.deal.deal_business != 'P' && this.deal.deal_business != 'H') {
     
      return {
        local_agent: {
          commission  : {required},
          gst         : {required},
          commission2 : {required},
          gst2        : {required},
        },
        deal: {
          
          selling_price: { required },
          acceptance_date: { required },
          contract_date: { required },
          deposit_due_date: { required },
          deposit: { required },
          listing_commission: {required},
          selling_commission: {required},
          listing_commission_gst: {required},
          selling_commission_gst: {required},
          properties     : {
            $each: {address : {required} }
          },

          buyers_sellers : {
            $each: { 
              first_name : {required},
              last_name  : {required},
              email: { email } 
            }
          },
          listing_brokers : {
            $each: {
              agent_name : {required},
              brokerage  : {required},
              commission : { required},
              gst        : { required}
              
            }
          },

          selling_brokers : {
            $each: {
              agent_name : {required},
              brokerage  : {required},
              commission : {  required},
              gst: {  required}
              
            }
          },
        },

        local_agent_sub_commissions : {
            $each: {
              commission : {  required},
              gst        : {  required}
            }
          }
      }
     
    }  else {
      return {
        local_agent: {
          commission : {required},
          gst : {required},
        },
        deal: {
         
          selling_price: {  },
          acceptance_date: {  },
          contract_date: {  },
          deposit_due_date: {  },
          deposit: {  },
          listing_commission: {},
          selling_commission: {},
          listing_commission_gst: {},
          selling_commission_gst: {},
          properties     : {
            $each: {address : {} }
          },

          buyers_sellers : {
            $each: { 
              first_name : {},
              last_name  : {},
              email: {  } 
            }
          },
          listing_brokers : {
            $each: {
              agent_name : {},
              brokerage  : {},
              commission : { },
              gst        : { }
              
            }
          },

          selling_brokers : {
            $each: {
              agent_name : {},
              brokerage  : {},
              commission : { },
              gst: { }
              
            }
          },
        },
      }
    }
  },


  methods: {



    formSubmit() {
      console.log(this.deal)

      this.$v.$touch();

      if (this.$v.$invalid == false) {
        let other_brokers = []
        this.deal.agents  =[]
        this.local_agent.sub_commissions = this.local_agent_sub_commissions
        this.deal.agents.push(this.local_agent)
        this.deal.agents.map(e=> e.agent_type = this.deal.deal_type == 'L' ? '1': '2')
        
        other_brokers.push(...this.deal.listing_brokers, ...this.deal.selling_brokers)

        other_brokers.map(e => {
          if (e.type == 'Selling Broker' || e.type == 'Listing Broker') {
            if (!e.first_name || e.first_name == '' || !e.last_name || e.last_name == '') {
              if (e.agent_name) {
                let arr = e.agent_name.split(" ")
                e.first_name = arr[0]
                e.last_name  = arr.length > 1? arr[1] : arr[0]
              }
            }

            if (!e.name || e.name == '') {
              e.name = e.brokerage
            }
          }
        })
        let data = {
          deal_id           : this.deal.order_deal_no,
          basic: {
            order_deal_id          : this.deal.order_deal_no,
            price                  : this.deal.selling_price,
            contract_date          : this.deal.contract_date,
            acceptance_date        : this.deal.acceptance_date,
            completion_date        : this.deal.completion_date,
            adjustment_date        : this.deal.adjustment_date,
            possession_date        : this.deal.possession_date,
            subject_remove_date    : this.deal.subject_remove_date,
            is_subject_deal        : this.deal.no_subject ? 0 : 1,
            recission_date         : this.deal.recission_date,
            deal_type              : this.deal.deal_type,
            listing_commission     : this.deal.listing_commission,
            selling_commission     : this.deal.selling_commission,
            listing_commission_gst : this.deal.listing_commission_gst,
            selling_commission_gst : this.deal.selling_commission_gst,
            mls                    : this.deal.mls,
            deal_business          : this.deal.deal_business
          },

          deposits          : [{ deposit: this.deal.deposit, due_date: this.deal.deposit_due_date, trust: this.deal.deposit_trust_by }],
          properties        : this.deal.properties,
          agents            : this.deal.agents,
          buyer_seller_list : this.deal.buyers_sellers,
          lawyer_list       : this.deal.lawyers,
          other_broker_list : other_brokers
        }
        getDealBasicApi().modify_deal(data).then(res => {
          if (res.errCode == 0) {
            this.$alertify.message("Deal is created");
            this.$router.push({ name: 'deal-pending-list' })
          } else {
            this.$alertify.error("Setup Deal failed:" + res.errCode);
          }
        })
      }

    },


    ///////search lawyer

    queryLawyer(str) {
      search_lawyer({ str: str }).then(res => {
        if (res.errCode == 0) {
          this.lawyer_data = []
          res.data.map(e => this.lawyer_data.push(e))

        }
      })
    },

    onLawyerSelected(evt, lawyer, idx) {

      lawyer.name  = evt.name
      lawyer.phone = evt.phone1
      lawyer.email = evt.email
      lawyer.address = evt.addr + ' ' + evt.city_name + ' ' + evt.postal
      lawyer.postal_code = evt.postal
      this.$refs['lawyer_address_'+idx][0].setValue(lawyer.address)
      this.$refs['lawyer_name_'+idx][0].inputValue = evt.name
      
    },

    onLawyerAddreessSelected(addr, evt) {
      let idx = evt.$attrs['lawyer_index']
      let lawyer = this.deal.lawyers[idx]
      lawyer.address = addr.full
    },


    //////////////Buyer/Seller

    onAddNewBuyerSeller() {
      this.deal.buyers_sellers.push({
        type: 'Buyer'
      })

      this.$nextTick(() => {
        let els = document.getElementsByClassName('buyer_seller_choice')
        new Choices(els[els.length - 1], {
          removeItemButton: false,
          itemSelectText  : '',
        });
      });
    },

    onBuyerSellerAddressSelected(addr, evt_cmp) {

      let item = this.deal.buyers_sellers[evt_cmp.$attrs['idx']]
      item.address = addr.full
      item.city = addr.city
    },

    onRemoveBuyerSeller(idx) {
      this.deal.buyers_sellers.splice(idx, 1)
    },


    //////////////Property
    onAddressSelected(addr, evt_cmp) {
      let item = this.deal.properties[evt_cmp.$attrs['idx']]
      item.address       = addr.full
      item.city_name     = addr.city
      item.province_name = addr.province
      item.street        = addr.street

      if (addr.reference) {
        postal_code_query({str : addr.reference}).then(res => {
          if (res.errCode == 0) {
            item.postal_code = res.data
            item.address    += item.postal_code
          }
        })
      }
    },


    onAddProperty() {
      let obj = {
        unit: '',
        pid: '',
        address: '',
        city_name     : '',
        province_name : '',
        postal_code   : '',
        street        : ''
      }

      this.deal.properties.push(obj)
    },

    onRemoveProperty(idx) {
      this.deal.properties.splice(idx, 1)
    },


    onSelectedLocalAgent(selected_agent) {
        this.local_agent.agent_name   = selected_agent.label 
        this.local_agent.agent_id     = selected_agent.agent.id

        this.local_agent.vin          = selected_agent.agent.vin
        this.local_agent.brokerage    = 'Remax Master'
        this.local_agent.address      = selected_agent.agent.address
      
        this.local_agent.agent_name   = selected_agent.agent.first_name + ' ' + selected_agent.agent.last_name
        this.local_agent.name         = 'Remax Master'

        this.local_agent.first_name   = selected_agent.agent.first_name
        this.local_agent.last_name    = selected_agent.agent.last_name
        this.local_agent.phone        = selected_agent.agent.phone
        this.local_agent.email        = selected_agent.agent.email
        
        this.local_agent.agent_vin    = selected_agent.agent.code
        this.local_agent.office_code  = selected_agent.agent.office_code


        if (this.deal.deal_business == 'R' || this.deal.deal_business == 'C' || this.deal.deal_business =='M') {

          let broker;
          if (this.deal.deal_type == 'L') {
            broker = this.deal.listing_brokers.find(e => e.temp_obj_id == this.local_agent.temp_obj_id)
          } else  if (this.deal.deal_type == 'S') {
            broker = this.deal.selling_brokers.find(e => e.temp_obj_id == this.local_agent.temp_obj_id)
          }

          broker.name       =  this.local_agent.brokerage   
          broker.brokerage  =  this.local_agent.brokerage 
          broker.agent_name =  this.local_agent.agent_name   
          broker.first_name =  this.local_agent.first_name   
          broker.last_name  =  this.local_agent.last_name   


       
          this.$refs['agent_broker_name_'+broker.temp_obj_id][0].update_input_agent_name(broker.agent_name)
        }
        
        
    },


    //////////////Agent
    onSelectedBrokerAgent(selected_agent) {
      
      console.log(selected_agent)


       
      let idx   = selected_agent.user_obj.broker_index
      let btype = selected_agent.user_obj.agent_type

      let broker = null
      
      if (btype == 'SELLING_BROKER') {
        broker = this.deal.selling_brokers[idx]
      } else {
        broker = this.deal.listing_brokers[idx]
      }
      

      broker.brokerage    = selected_agent.agent.brokerage_name
      broker.address      = selected_agent.agent.address
    
      broker.agent_name   = selected_agent.label
      broker.name         = broker.brokerage

      broker.first_name   = selected_agent.agent.first_name
      broker.last_name    = selected_agent.agent.last_name
      broker.phone        = selected_agent.agent.phone
      broker.email        = selected_agent.agent.email
      
      broker.agent_vin    = selected_agent.agent.vin
      broker.office_code  = selected_agent.agent.office_code

    },

    onAddNewAgent() {
      
      this.local_obj_id ++
      let obj = {
        vin: '',
        name: '',
        agent_type: (this.deal.deal_type == 'L' ? '1': '2'),
        commission: 0,
        gst : 0,
        total :0,
        commission_required : true,

        commission_2 : 0,
        gst_2 : 0,
        choice: {},
        search_type : 'LOCAL',
        local_checked : true,
        temp_obj_id   : this.local_obj_id
      }

      this.deal.agents.push(obj)
    
    },

    removeAgentItem(idx) {
      this.deal.agents.splice(idx, 1)
      this.update_total_commission()
   
    },


    
    onAddNewLawyer() {
      
      this.deal.lawyers.push({ type  :'Buyer Lawyer'})

      this.$nextTick(() => {
        let els = document.getElementsByClassName('lawyer_choice')
        new Choices(els[els.length - 1], {
            removeItemButton: false,
            itemSelectText  : '',
        });
      })
        

    
    },

    onRemoveLawyer(idx) {
      this.deal.lawyers.splice(idx, 1)
    },


    onAddListingBroker() {
      this.deal.listing_brokers.push({ agent_name_required: false, agent_name : '', agent_vin: '', brokerage : '' , email :'', phone : '', name : '', commission: 0, gst : 0, total : 0, type  :'Listing Broker', temp_obj_id   : ++this.local_obj_id})
    },

    onAddSellingBroker() {
      this.deal.selling_brokers.push({ agent_name_required: false, agent_name : '' , agent_vin: '', brokerage : '' , email :'', phone : '', name : '', commission: 0, gst : 0, total : 0, type  :'Selling Broker' , temp_obj_id   : ++this.local_obj_id})
    },

    onRemoveListingBroker(idx) {
      this.deal.listing_brokers.splice(idx, 1)
      this.update_total_commission()
    },

    onRemoveSellingBroker(idx) {
      
      this.deal.selling_brokers.splice(idx, 1)
      this.update_total_commission()
    },



    onAddSubTrade() {
      let sub_deal_arr =['A','B','C','D','E','F','G','H','I']
      let idx = 1


      this.local_agent_sub_commissions.push({commission : 0, gst : 0, agent_name : '', sub_deal_id :''})
      this.local_agent_sub_commissions.map(e => e.sub_deal_id = this.deal.order_deal_no +'-'+sub_deal_arr[idx++])
    },

    onRemoveSubTrade(ridx) {
      this.local_agent_sub_commissions.splice(ridx, 1)
      let sub_deal_arr =['A','B','C','D','E','F','G','H','I']
      let idx = 1
      this.local_agent_sub_commissions.map(e => e.sub_deal_id = this.deal.order_deal_no +'-'+sub_deal_arr[idx++])

      this.update_local_broker_commission()
      this.update_total_commission()
    },
   


    validate_gst_required() {

      this.local_agent.total  = financial(Number(this.local_agent.commission)  + this.local_agent.gst, 2)


      this.$refs['local_agent_commission'].setValue(this.local_agent.commission)
      this.$refs['local_agent_commission_gst'].setValue(this.local_agent.gst)
      this.$refs['local_agent_commission_total'].setValue(this.local_agent.total)

    

      if (this.deal.deal_business == 'R' || this.deal.deal_business == 'C' || this.deal.deal_business == 'M') {
        this.update_local_broker_commission()
        this.update_total_commission()
      }
    },

    validate_comm_required() {

    
      if (this.local_agent.commission) {
        this.local_agent.gst  = financial(Number(this.local_agent.commission) * 0.05, 2)
      } else {
        this.local_agent.gst  = 0
      }

      this.local_agent.total  = financial(Number(this.local_agent.commission)  + this.local_agent.gst, 2)

      this.$refs['local_agent_commission'].setValue(this.local_agent.commission)
      this.$refs['local_agent_commission_gst'].setValue(this.local_agent.gst)
      this.$refs['local_agent_commission_total'].setValue(this.local_agent.total)


      if (this.deal.deal_business == 'R' || this.deal.deal_business == 'C' || this.deal.deal_business == 'M') {
        this.update_local_broker_commission()
        this.update_total_commission()
      }


    },


    other_comm_watcher() {

      this.deal.listing_brokers.map(e =>{
        if (e.commission) {
          e.gst  = financial(Number(e.commission) * 0.05, 2)
        } else {
          e.gst  = 0
        }

        e.total  = financial(Number(e.commission)  + e.gst, 2)

        this.$refs['agent_broker_comm_gst_'+e.temp_obj_id][0].setValue(e.gst)
        this.$refs['agent_broker_comm_total_'+e.temp_obj_id][0].setValue(e.total)

      })


      this.deal.selling_brokers.map(e =>{
        if (e.commission) {
          e.gst  = financial(Number(e.commission) * 0.05, 2)
        } else {
          e.gst  = 0
        }

        e.total  = financial(Number(e.commission)  + e.gst, 2)

        this.$refs['agent_broker_comm_gst_'+e.temp_obj_id][0].setValue(e.gst)
        this.$refs['agent_broker_comm_total_'+e.temp_obj_id][0].setValue(e.total)

      })

      this.update_total_commission()
    },

    other_comm_gst_watcher() {
  
      this.deal.listing_brokers.map(e =>{
        e.total  = financial(Number(e.commission)  + Number(e.gst), 2)
        this.$refs['agent_broker_comm_total_'+e.temp_obj_id][0].setValue(e.total)
      })

      this.deal.selling_brokers.map(e =>{
        e.total  = financial(Number(e.commission)  + Number(e.gst), 2)
        this.$refs['agent_broker_comm_total_'+e.temp_obj_id][0].setValue(e.total)
      })

    },


    watch_sub_commission(evt, idx) {
     
     let comm = financial(Number(evt), 2)
     let gst  = financial(Number(evt) * 0.05, 2)

     this.$refs['local_agent_sub_commission_gst_ref_'+idx][0].setValue(gst)
     this.$refs['local_agent_sub_commission_total_ref_'+idx][0].setValue(financial(comm + gst))

     this.update_local_broker_commission()
     this.update_total_commission()
   },

   watch_sub_commission_gst(evt, idx) {
     let comm = this.local_agent_sub_commissions[idx].commission || 0
     let gst  = financial(Number(evt), 2)

     this.$refs['local_agent_sub_commission_gst_ref_'+idx][0].setValue(gst)
     this.$refs['local_agent_sub_commission_total_ref_'+idx][0].setValue(financial(comm + gst))

     this.update_local_broker_commission()
     this.update_total_commission()
   },


    onSideChange() {
      this.deal.listing_brokers = this.deal.listing_brokers.filter(e => e.temp_obj_id != this.local_agent.temp_obj_id)
      this.deal.selling_brokers = this.deal.selling_brokers.filter(e => e.temp_obj_id != this.local_agent.temp_obj_id)

      if (this.deal.deal_type == 'L')  {
        this.local_agent.type = 'Listing Broker'
        this.deal.listing_brokers.push(Object.assign({}, this.local_agent))
      } else if (this.deal.deal_type == 'S')  {
        this.local_agent.type = 'Selling Broker'
        this.deal.selling_brokers.push(Object.assign({}, this.local_agent))
      }

      this.update_local_broker_commission()
      this.update_total_commission()
    },



    update_total_commission() {

      this.deal.listing_commission       = 0
      this.deal.listing_commission_gst   = 0
      this.deal.listing_commission_total = 0
      this.deal.selling_commission       = 0
      this.deal.selling_commission_gst   = 0
      this.deal.selling_commission_total = 0

    
      this.deal.listing_commission         = financial(this.deal.listing_brokers.reduce((a, c) => a += Number(c.commission), 0), 2)
      this.deal.listing_commission_gst     = financial(this.deal.listing_brokers.reduce((a, c) => a += Number(c.gst), 0), 2)
      this.deal.selling_commission         = financial(this.deal.selling_brokers.reduce((a, c) => a += Number(c.commission), 0), 2)
      this.deal.selling_commission_gst     = financial(this.deal.selling_brokers.reduce((a, c) => a += Number(c.gst), 0), 2)



      this.deal.selling_commission_total   = financial(this.deal.selling_commission + this.deal.selling_commission_gst, 2)
      this.deal.listing_commission_total   = financial(this.deal.listing_commission + this.deal.listing_commission_gst, 2)

  


      
      this.$refs['listing_commission_ref'].setValue(this.deal.listing_commission )
      this.$refs['listing_commission_gst_ref'].setValue(this.deal.listing_commission_gst )
      this.$refs['listing_commission_total_ref'].setValue(this.deal.listing_commission_total )
      this.$refs['selling_commission_ref'].setValue(this.deal.selling_commission )
      this.$refs['selling_commission_gst_ref'].setValue(this.deal.selling_commission_gst )
      this.$refs['selling_commission_total_ref'].setValue(this.deal.selling_commission_total )
    },


    update_local_broker_commission() {
      let broker;
      if (this.deal.deal_type == 'L') {
        broker = this.deal.listing_brokers.find(e => e.temp_obj_id == this.local_agent.temp_obj_id)
      } else  if (this.deal.deal_type == 'S') {
        broker = this.deal.selling_brokers.find(e => e.temp_obj_id == this.local_agent.temp_obj_id)
      }


      if (broker) {
        broker.commission = Number(this.local_agent.commission) ||0
        broker.gst        = Number(this.local_agent.gst) || 0
        broker.total      = Number(this.local_agent.total) || 0


        if (this.deal.deal_business == 'M') {
          broker.commission += financial(this.local_agent_sub_commissions.reduce((a,c) => a += c.commission, 0), 2)
          broker.gst        += financial(this.local_agent_sub_commissions.reduce((a,c) => a += c.gst, 0), 2)
          broker.total      += financial(this.local_agent_sub_commissions.reduce((a,c) => a += c.total, 0), 2)
        }


        // console.log(broker.temp_obj_id, this.$refs, this.$refs['agent_broker_comm_1000'], this.$refs['agent_broker_comm_1000'])
        this.$refs['agent_broker_comm_'+broker.temp_obj_id][0].setValue(broker.commission)
        this.$refs['agent_broker_comm_gst_'+broker.temp_obj_id][0].setValue(broker.gst)
        this.$refs['agent_broker_comm_total_'+broker.temp_obj_id][0].setValue(broker.total)

      }
     
    },

  },


  

  data() {
    return {
      title: "Deal",
      items: [
        {
          text: "Deal List",
          href: "/deal/pending_deal",
        },
        {
          text: "Edit Deal",
          active: true,
        },
      ],
      gst_required : false,
      other_broker_name_required : false,

      
     

      deal: {
        deal_business    : 'R',
        mls              : '',
        deal_type        : 'L',
        deposit          : 0,
        deposit_due_date : '',
        deposit_trust_by : '0',
        order_deal_no    : '',
        no_subject       : '',
        agents           : [],
        properties       : [],
        buyers_sellers   : [],
        lawyers          : [],
        other_brokers    : [],
        listing_brokers  : [],
        selling_brokers  : [],
      },


      local_agent :  {
        name        : '',
        agent_name  : '',
        label       : '',
        first_name  : '',
        last_name   : '',
        brokerage   : '',
        commission  : 0,
        gst         : 0,
        total       : 0,
        commission2 : 0,
        gst2        : 0,
        total2      : 0,
        type        :'Listing Broker',
        temp_obj_id : 1000, // make sure same with local_obj_id
        is_local    : true,
      },

      local_agent_sub_commissions : [],
      
      searched_property_address: '',
      searched_lawyer: '',
      buery_seller_address: '',
      address_data: [],
      lawyer_data: [],
      lawyer_choices: [],

      local_obj_id : 1000,

      deal_type_choice: {},
      deal_type_list : [
        {label : 'LISTING', value : 'L', selected: false},
        {label : 'SELLING', value : 'S', selected: false},
      ],

      trust_by_choice: {},
      trust_by_list : [
        {label : 'US', value : '1', selected: false},
        {label : 'OTHER', value : '0', selected: false},
      ],

    };
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
    AgentAutoComplete,
    AddressAutoComplete,
    VueBootstrapTypeahead,
    CurrencyInput,

  },

  created() {
    


  },

  mounted() {

   
    this.deal_type_choice = new Choices('#deal_type', {choices: this.deal_type_list})
    this.trust_by_choice = new Choices('#deposit_trust_by', {choices: this.trust_by_list})

    getDealApi().detail({ deal_id: this.$route.query.deal_id }).then((res) => {
      if (res.errCode == 0) {
          Object.assign(this.deal, res.data)
          this.deal.order_deal_no = this.deal.order_deal_id
        
      
        if (this.deal.deal_business == 'R') {
          this.deal.deal_business_name = 'RESIDENTIAL'
        } else if (this.deal.deal_business == 'C') {
          this.deal.deal_business_name = 'COMMERICIAL'
        } else if (this.deal.deal_business == 'P') {
          this.deal.deal_business_name = 'PROPERTY MANAGEMENT'
        } else if (this.deal.deal_business == 'M') {
          this.deal.deal_business_name = 'Pre Sale'
        } else if (this.deal.deal_business == 'H') {
          this.deal.deal_business_name = 'REFERRAL'
        } else if (this.deal.deal_business == 'T') {
          this.deal.deal_business_name = 'Tenant Replacement'
        }

        if (this.deal.deposits && this.deal.deposits.length > 0) {
          this.deal.deposit             = this.deal.deposits[0].deposit
          this.deal.deposit_due_date    = this.deal.deposits[0].due_date
          this.deal.deposit_trust_by    = this.deal.deposits[0].is_trust_by_us? this.deal.deposits[0].is_trust_by_us : '0'
        }


        //TODO Now we only support one loacal Agent
        let agent = this.deal.agents[0]
        this.local_agent = {
          //for broker field
          agent_vin   : agent.agent_vin,
          agent_name  : agent.first_name+' '+agent.last_name,
          first_name  : agent.first_name,
          last_name   : agent.last_name,
          name        : agent.brokerage,
          brokerage   : agent.brokerage,
          commission  : agent.origin_commission,
          gst         : agent.gst,
          total       : agent.origin_commission + agent.gst,
          commission2 : 0,
          gst2        : 0,
          total2      : 0,
          type        : this.deal.deal_type == 'L'?'Listing Broker' : "Selling Broker",
          temp_obj_id : this.local_obj_id, // make sure same with local_obj_id
          is_local    : true,
          agent_id    : agent.agent_id
        }

        if (this.deal.deal_business == 'M') {
          this.local_agent.commission  = financial(Number(this.deal.links[0].agents[0].origin_commission), 2)
          this.local_agent.gst         = financial(Number(this.deal.links[0].agents[0].gst), 2)
          this.local_agent.total       = this.local_agent.commission  + this.local_agent.gst

          for (let i = 1; i < this.deal.links.length; i++) {
            this.local_agent_sub_commissions.push({
              sub_deal_id : this.deal.links[i].order_deal_id,
              commission  :   financial(Number(this.deal.links[i].agents[0].origin_commission), 2),
              gst         :   financial(Number(this.deal.links[i].agents[0].origin_gst), 2),
              total       :   financial(Number(this.deal.links[i].agents[0].origin_gst + this.deal.links[i].agents[0].origin_commission), 2),
            })
          }

          
        }


        if (!this.deal.buyers_sellers) {
            this.deal.buyers_sellers = []
        }

        if (!this.deal.properties) {
          this.deal.properties = []
        }

        if (!this.deal.listing_brokers) {
          this.deal.listing_brokers = []
        }
        this.deal.listing_brokers.map(e =>{
          e.agent_name     = e.first_name+' '+e.last_name
          e.brokerage      = e.name
          e.temp_obj_id    = ++ this.local_obj_id
        })

        if (!this.deal.selling_brokers) {
          this.deal.selling_brokers = []
        }
        this.deal.selling_brokers.map(e =>{
          e.agent_name     = e.first_name+' '+e.last_name
          e.brokerage      = e.name
          e.temp_obj_id    = ++ this.local_obj_id
        })


        //Correct Local Agent temp_obj id
        let broker = this.deal.selling_brokers.find(e => e.agent_vin == this.local_agent.agent_vin)
        if (!broker) {
          broker = this.deal.listing_brokers.find(e => e.agent_vin == this.local_agent.agent_vin)
          if (broker) {
            broker.temp_obj_id    = this.local_agent.temp_obj_id
          }
        } else {
          broker.temp_obj_id    = this.local_agent.temp_obj_id
        }
        

        console.log(this.deal.selling_brokers, this.deal.listing_brokers)

        this.deal.buyers_sellers.push(...this.deal.buyers, ...this.deal.sellers)
        

        this.deal.no_subject = this.deal.is_subject_deal ? false : true

        this.$refs['deal_selling_price'].setValue(this.deal.selling_price)
        this.$refs['deal_deposit'].setValue(this.deal.deposit)
       

        this.deal_type_choice.clearChoices()
        this.deal_type_list.map(e => e.selected = (e.value == this.deal.deal_type?true : false))
        this.deal_type_choice.setChoices(this.deal_type_list)


        this.trust_by_choice.clearChoices()
        this.trust_by_list.map(e => e.selected = (e.value == this.deal.deposit_trust_by?true : false))
        
        this.trust_by_choice.setChoices(this.trust_by_list)


        this.$refs['local_agent_commission'].setValue(this.local_agent.commission)
        this.$refs['local_agent_commission_gst'].setValue(this.local_agent.gst)
        this.$refs['local_agent_commission_total'].setValue(this.local_agent.total)



        //must be at last, to  make sure broker components are initialized
       

        
        this.$nextTick(() => {

         

          //have to wait refs component set up
          setTimeout(() => {
            let idx = 0
            this.deal.lawyers.map(e => {
              this.$refs['lawyer_name_'+idx][0].inputValue = e.name
              idx ++
            })

            this.onSideChange()


            this.$refs['local_agent_search_ref'].update_input_agent_name(this.local_agent.agent_name)

            this.$refs['agent_broker_comm_'+broker.temp_obj_id][0].setValue(broker.commission)
            this.$refs['agent_broker_comm_gst_'+broker.temp_obj_id][0].setValue(broker.gst)
            this.$refs['agent_broker_comm_total_'+broker.temp_obj_id][0].setValue(broker.total)
           
          }, 1000)
         
        })
    
      }

    })




  },

  watch: {

    searched_property_address(new_search_name) {
      this.queryProperty(new_search_name)
    },


    searched_lawyer(new_search_name) {
      this.queryLawyer(new_search_name)
    },



  },

  computed: {

    tab_resident_type() {
      return this.deal.deal_business != 'H' && this.deal.deal_business != 'P'
    },
   
    tab_referral_type() {
      return this.deal.deal_business == 'H'
    },

    tab_replacement_fee() {
      return this.deal.deal_business == 'P'
    },


  

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-4">New Deal Info</h5>
            <form class="needs-validation" @submit.prevent="formSubmit">

              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Deal Type</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <div class="row gap-4">
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" >Deal No.</label>
                          <input type="text" :value="deal.order_deal_no" class="form-control" readonly />
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Business Type</label>
                          <input type="text" class="form-control" v-model="deal.deal_business_name" readonly/>
                         
                        </div>
                      </div>

                      <div class="col-lg-2" :class="(deal.deal_business == 'P' || deal.deal_business == 'H') ? 'd-none' :''">
                        <div class="mb-3">
                          <label class="form-label">Side</label>
                          <select id="deal_type" v-model="deal.deal_type" class="form-control" @change="onSideChange">
                          
                          </select>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
              <!-- end card -->

              <!--  tab referral fee /replacement -->
              <div class="row"   v-if="tab_referral_type || tab_replacement_fee" :class="tab_referral_type || tab_replacement_fee?'':'d-none'">
                <!-- Agent and Commission -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          01
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">  Agent & Commission</h5>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row" >
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" >Agent</label>
                          <AgentAutoComplete :init_data="{user_obj : local_agent, agent_name : local_agent.agent_name}" @onSelected="onSelectedLocalAgent"  autocomplete="off" />
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label" >Commission</label>
                          <CurrencyInput v-model.lazy="local_agent.commission"   :ref="'local_agent_commission'"   :class="{ 'is-invalid': $v.local_agent.commission.$error }" @input="validate_comm_required" @blur="validate_comm_required"  />
                          <div v-if="$v.local_agent.commission.$error " class="invalid-feedback">
                            <span v-if="$v.local_agent.commission.$error ">This value is required.</span>
                          </div>

                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label" >Gst</label>
                          <CurrencyInput v-model.lazy="local_agent.gst"  :ref="'local_agent_commission_gst'"  :class="{ 'is-invalid': $v.local_agent.gst.$error }" @input="validate_gst_required" @blur="validate_gst_required"  />
                            <div v-if="$v.local_agent.gst.$error" class="invalid-feedback">
                            <span v-if="$v.local_agent.gst.$error">This value is required.</span>
                          </div>

                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" >Total</label>
                          <CurrencyInput v-model="local_agent.total"  :ref="'local_agent_commission_total'"  readonly />
                        </div>
                      </div>

                    
                    </div>

                  </div>
                </div>
                <!-- end card -->
              </div>
              <!-- End Tab-->

              <!--  tab resident /commercial -->
              <div class="row"  v-if="tab_resident_type"  :class="tab_resident_type?'':'d-none'">
                <!-- Resident or commercial tab -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          01 
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">General Info</h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <div>
                      <div class="row gap-4">

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Price</label>
                            <CurrencyInput v-model.lazy="deal.selling_price" :class="{ 'is-invalid': $v.deal.selling_price.$error }" ref="deal_selling_price" />
                            <div v-if="$v.deal.selling_price.$error" class="invalid-feedback">
                              <span v-if="!$v.deal.selling_price.required">This value is required.</span>
                            </div>

                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Deposit</label>
                            <CurrencyInput v-model.lazy="deal.deposit" ref="deal_deposit"/>
                            <!-- input type="text" v-model="deal.deposit" class="form-control" placeholder="Enter Amount"
                              /-->
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Deposit Date</label>
                            <flat-pickr v-model="deal.deposit_due_date" placeholder="Select a date" class="form-control"
                              :class="{ 'is-invalid': $v.deal.deposit_due_date.$error }"></flat-pickr>
                            <div v-if="$v.deal.deposit_due_date.$error" class="invalid-feedback">
                              <span v-if="!$v.deal.deposit_due_date.required">This value is required.</span>
                            </div>

                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Trust By</label>
                            <select id="deposit_trust_by" v-model="deal.deposit_trust_by" class="form-control">
                            </select>
                          </div>
                        </div>
                      </div>


                      <div class="row  mt-4">
                        <div class="col-sm-2">
                          <div class="mb-3">
                            <label class="form-label" >Contract Date</label>
                            <flat-pickr v-model="deal.contract_date" placeholder="Select a date" class="form-control"
                              :class="{ 'is-invalid': $v.deal.contract_date.$error }"></flat-pickr>
                            <div v-if="$v.deal.contract_date.$error" class="invalid-feedback">
                              <span v-if="!$v.deal.contract_date.required">This value is required.</span>
                            </div>

                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-designation-input">Acceptance Date</label>
                            <flat-pickr v-model="deal.acceptance_date" placeholder="Select a date" class="form-control"
                              :class="{ 'is-invalid': $v.deal.acceptance_date.$error }"></flat-pickr>
                            <div v-if="$v.deal.acceptance_date.$error" class="invalid-feedback">
                              <span v-if="!$v.deal.acceptance_date.required">This value is required.</span>
                            </div>
                          </div>
                        </div>
                       
                        <div class="col-sm-1">
                          <div class="form-check mt-4">
                            <input type="checkbox" class="form-check-input" 
                              v-model="deal.no_subject" />
                            <label class="form-check-label" for="formrow-customCheck">No Subject</label>
                          </div>
                        </div>

                        <div class="col-sm-2" :class="deal.no_subject?'d-none':'block'">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-designation-input">Subject Remove Date</label>
                            <flat-pickr v-model="deal.subject_remove_date" placeholder="Select a date"
                              class="form-control" :disabled="deal.no_subject ? true : false"></flat-pickr>
                          </div>
                        </div>

                        <div class="col-sm-2" >
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-designation-input">Recission  Date</label>
                            <flat-pickr v-model="deal.recission_date" placeholder="Select a date"
                              class="form-control" ></flat-pickr>
                          </div>
                        </div>

                        <div class="col-sm-2">
                          <div class="mb-3">
                            <label class="form-label" >Completion Date</label>
                            <flat-pickr v-model="deal.completion_date" placeholder="Select a date" class="form-control"
                              :class="{ 'is-invalid': $v.deal.acceptance_date.$error }"></flat-pickr>
                            <div v-if="$v.deal.acceptance_date.$error" class="invalid-feedback">
                              <span v-if="!$v.deal.acceptance_date.required">This value is required.</span>
                            </div>

                          </div>
                        </div>


                      </div>
                      <!-- end row-->

                    </div>
                  </div>
                </div>
                <!-- end card -->

                <!-- Buyer & Seller  -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          02
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Buyer & Seller</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <b-button variant="primary" @click="onAddNewBuyerSeller" class="col-3">Add Buyer/Seller</b-button>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row mt-3" v-for="(bs, idx) in deal.buyers_sellers" :key="'buyer_seller_' + idx">
                      <div class="row">
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">{{ bs.type }} {{ idx + 1 }}</label>
                            <select class="form-control buyer_seller_choice" v-model="bs.type">
                              <option value="Buyer">Buyer</option>
                              <option value="Seller">Seller</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">{{ bs.type }} Type</label>
                            <select class="form-control buyer_seller_choice" v-model="bs.is_coporation">
                              <option value="0">Indivual</option>
                              <option value="1">Corp</option>
                            </select>
                          </div>
                        </div>
                        <div :class=" bs.is_coporation == 1? 'col-lg-3' :'col-lg-2'">
                          <div class="mb-3">
                            <label class="form-label">{{ bs.is_coporation == 1? 'CORP Name' : 'First Name'}}</label>
                            <input type="text" class="form-control" v-model="bs.first_name" maxlength="50" :class="{'is-invalid': $v.deal.buyers_sellers.$each[idx].first_name.$error}"/>
                            <div v-if="$v.deal.buyers_sellers.$each[idx].first_name.$error" class="invalid-feedback">
                              <span v-if="$v.deal.buyers_sellers.$each[idx].first_name.$error">This value is required.</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2" :class="bs.is_coporation == 1?'d-none':'d-block'">
                          <div class="mb-3">
                            <label class="form-label">Last Name</label>
                            <input type="text" class="form-control" v-model="bs.last_name" maxlength="50" :class="{'is-invalid': $v.deal.buyers_sellers.$each[idx].last_name.$error}"/>
                            <div v-if="$v.deal.buyers_sellers.$each[idx].last_name.$error" class="invalid-feedback">
                              <span v-if="$v.deal.buyers_sellers.$each[idx].last_name.$error">This value is required.</span>
                            </div>
                          </div>
                        </div>
                      
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Phone</label>
                            <input type="text" class="form-control"  v-mask="'(###) ###-####'" v-model="bs.phone" maxlength="30"/>
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Email</label>
                            <input  class="form-control" v-model="bs.email" maxlength="80" type="email"  :class="{  'is-invalid': $v.deal.buyers_sellers.$each[idx].email.$error , }"/>
                            <div v-if="$v.deal.buyers_sellers.$each[idx].email.$error" class="invalid-feedback">
                              <span v-if="$v.deal.buyers_sellers.$each[idx].email.$error">This value should be a valid email.</span>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-1">
                          <div class="row icon-demo-content-10 mb-3 d-flex align-items-start justify-content-md">
                            <label class="form-label" > {{ ' ' }}&nbsp;</label>
                            <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveBuyerSeller(idx)">
                              <i class="uil-times font-size-6"></i>
                            </div>
                          </div>
                        </div>

                      </div>
                      
                      <div class="row">
                        <div class="col-lg-1">
                          <div class="mb-3">
                            <label>Unit</label>
                            <input type="text" class="form-control" v-model="bs.unit" />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label" >Address</label>
                            <AddressAutoComplete :idx="idx" v-model="bs.address" :init_data="{address:bs.address,}" @onSelected="onBuyerSellerAddressSelected" autocomplete="off" />
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label>Postal Code</label>
                            <input type="text" class="form-control" v-model="bs.postal_code" />
                          </div>
                        </div>
                        <div class="col-lg-2">
                        </div>
                      
                      </div>
                      <div class="row mb-4">
                        <hr class="my-1" />
                      </div>

                    </div>

                  </div>
                </div>
                <!-- end card -->



                <!-- property address -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          03
                        </div>
                      </div>

                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Property Address</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <b-button variant="primary" @click="onAddProperty" class="col-3">Add Address</b-button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label>MLS</label>
                          <input type="text" class="form-control" v-model="deal.mls" />
                        </div>
                      </div>
                    </div>

                    <div class="row" v-for="(property, idx) in deal.properties" :key="'property_addr_' + idx">
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label>PID</label>
                          <input type="text"  class="form-control" v-mask="'###-###-###'"  v-model="property.pid" />
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label>Unit</label>
                          <input type="text" class="form-control" v-model="property.unit" />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label for="workexperience-companyname-input">Property Address</label>
                          <AddressAutoComplete :idx="idx" v-model="property.address"  @onSelected="onAddressSelected" autocomplete="off" :init_data="{address:property.address, style_class: { 'is-invalid': $v.deal.properties.$each[idx].address.$error } }" />
                          <div v-if="$v.deal.properties.$each[idx].address.$error" class="invalid-feedback">
                            <span v-if="$v.deal.properties.$each[idx].address.$error">This value is required.</span>
                          </div>
                          
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label>Postal Code</label>
                          <input type="text" class="form-control" v-model="property.postal_code" />
                        </div>
                      </div>


                      <div class="col-lg-2">
                        <div class="row icon-demo-content-10 mb-3">
                          <label class="form-label" > {{ ' ' }}&nbsp;</label>
                          <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveProperty(idx)" >
                            <i class="uil-times font-size-4"></i>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <!-- end card -->


                <!-- Brokerage Local Agent -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          04
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Brokerage Agent</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <b-button variant="primary" @click="onAddSubTrade" class="col-3">Add Sub Trade</b-button>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row">
                      <div class="row">
                        <div class="col-lg-3">
                          <div class="mb-3">
                            <label class="form-label" >Agent</label>
                            <AgentAutoComplete  :ref="'local_agent_search_ref'"  :agent_idx="0" :init_data="{ user_obj : local_agent, agent_name : local_agent.agent_name}" :search_type="'LOCAL'" @onSelected="onSelectedLocalAgent"
                              autocomplete="off" :class="{ 'is-invalid': true }" />
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Commission<span v-if="deal.deal_type == 'M'">(Phase 1)</span></label>
                            <CurrencyInput v-model.lazy="local_agent.commission"   :ref="'local_agent_commission'"   :class="{ 'is-invalid': $v.local_agent.commission.$error }" @input="validate_comm_required" @blur="validate_comm_required"  />
                            <div v-if="$v.local_agent.commission.$error" class="invalid-feedback">
                              <span v-if="$v.local_agent.commission.$error">This value is required.</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-1">
                          <div class="mb-3">
                            <label class="form-label" >Gst</label>
                            <CurrencyInput v-model.lazy="local_agent.gst"  :ref="'local_agent_commission_gst'"  :class="{ 'is-invalid': $v.local_agent.gst.$error }" @input="validate_gst_required" @blur="validate_gst_required"  />
                              <div v-if="$v.local_agent.gst.$error" class="invalid-feedback">
                                <span v-if="$v.local_agent.gst.$error">This value is required.</span>
                              </div>
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Total</label>
                            <CurrencyInput v-model.lazy="local_agent.total"  :ref="'local_agent_commission_total'"   readonly  />
                            
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" v-for="(sub, idx) in local_agent_sub_commissions" :key="'sub_trade_'+idx" >
                      <div class="row">
                        <div class="col-lg-3">
                          <div class="mb-3">
                            <label class="form-label" >Sub Deal</label>
                            <input type="text" class="form-control" v-model="sub.sub_deal_id" maxlength="80" :readonly="true"/>
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Commission</label>
                            <CurrencyInput v-model.lazy="sub.commission"   :ref="'local_agent_sub_commission_ref_'+idx"   :class="{ 'is-invalid': $v.local_agent_sub_commissions.$each[idx].commission.$error }" @input="watch_sub_commission($event,idx)" @blur="watch_sub_commission($event, idx)"  />
                            <div v-if="$v.local_agent_sub_commissions.$each[idx].commission.$error" class="invalid-feedback">
                              <span v-if="$v.local_agent_sub_commissions.$each[idx].commission.$error">This value is required.</span>
                            </div>

                          </div>
                        </div>
                        <div class="col-lg-1">
                          <div class="mb-3">
                            <label class="form-label" >Gst</label>
                            <CurrencyInput v-model.lazy="sub.gst"  :ref="'local_agent_sub_commission_gst_ref_'+idx"  :class="{ 'is-invalid': $v.local_agent_sub_commissions.$each[idx].gst.$error }" @input="watch_sub_commission_gst($event,idx)" @blur="watch_sub_commission_gst($event,idx)"  />
                            <div v-if="$v.local_agent_sub_commissions.$each[idx].gst.$error" class="invalid-feedback">
                              <span v-if="$v.local_agent_sub_commissions.$each[idx].gst.$error">This value is required.</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Total</label>
                            <CurrencyInput v-model.lazy="sub.total"  :ref="'local_agent_sub_commission_total_ref_'+idx"    readonly  />
                          </div>
                        </div>
                        <div class="col-lg-1">
                          <div class="row icon-demo-content-10 mb-3">
                            <label class="form-label" > {{ ' ' }}&nbsp;</label>
                            <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveSubTrade(idx)">
                              <i class="uil-times font-size-4"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <!-- end row commission 2-->



                  </div>
                </div>
                <!-- end card Agent & Commission-->


            
                 <!-- Listing Broker -->
                 <div class="card border shadow-none mb-5" >
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          04A
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Listing Agent & Broker</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <b-button variant="primary" @click="onAddListingBroker" class="col-3">Add Entry</b-button>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row" v-for="(broker, idx) in deal.listing_brokers" :key="'agent_' + idx + '_listing_broker_' + broker.temp_obj_id">
                     
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" >Agent</label>
                          <AgentAutoComplete :ref="'agent_broker_name_'+broker.temp_obj_id" :broker_idx="idx" v-model="broker.agent_name" :readonly="broker.is_local"  :init_data="{user_obj: {broker_index : idx, agent_type: 'LISTING_BROKER'}, agent_name : broker.agent_name, style_class: { 'is-invalid': $v.deal.listing_brokers.$each[idx].agent_name.$error } }" :show_brokerage="false" :search_type="'REGBV'" @onSelected="onSelectedBrokerAgent"
                            autocomplete="off" :class="{ 'is-invalid': true }" />
                            <div v-if="$v.deal.listing_brokers.$each[idx].agent_name.$error" class="invalid-feedback">
                              <span v-if="$v.deal.listing_brokers.$each[idx].agent_name.$error">This value is required.</span>
                            </div>
                
                        </div>
                      </div>
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label">Broker</label>
                          <input type="text" class="form-control" v-model="broker.brokerage" maxlength="80" :readonly="broker.is_local"  :class="{ 'is-invalid': $v.deal.listing_brokers.$each[idx].brokerage.$error }"/>
                          <div v-if="$v.deal.listing_brokers.$each[idx].brokerage.$error" class="invalid-feedback">
                            <span v-if="$v.deal.listing_brokers.$each[idx].brokerage.$error">This value is required.</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" >Commission</label>
                          <CurrencyInput v-model.lazy="broker.commission"   :ref="'agent_broker_comm_'+broker.temp_obj_id" @input="other_comm_watcher" @blur="other_comm_watcher" :readonly="broker.is_local"  :class="{ 'is-invalid': $v.deal.listing_brokers.$each[idx].commission.$error }" />
                          <div v-if="$v.deal.listing_brokers.$each[idx].commission.$error" class="invalid-feedback">
                            <span v-if="$v.deal.listing_brokers.$each[idx].commission.$error">This value is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label" >Gst</label>
                          <CurrencyInput v-model.lazy="broker.gst"  :ref="'agent_broker_comm_gst_'+broker.temp_obj_id"  @input="other_comm_gst_watcher" @blur="other_comm_gst_watcher"  :readonly="broker.is_local"  :class="{ 'is-invalid': $v.deal.listing_brokers.$each[idx].gst.$error }"  />
                          <div v-if="$v.deal.listing_brokers.$each[idx].gst.$error" class="invalid-feedback">
                            <span v-if="$v.deal.listing_brokers.$each[idx].gst.$error">This value is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label" >Total</label>
                          <CurrencyInput v-model.lazy="broker.total"  :ref="'agent_broker_comm_total_'+broker.temp_obj_id"   readonly :class="{ 'is-invalid': $v.deal.listing_brokers.$each[idx].gst.$error }" />
                          <div v-if="$v.deal.listing_brokers.$each[idx].gst.$error" class="invalid-feedback">
                            <span v-if="$v.deal.listing_brokers.$each[idx].gst.$error">This value is required.</span>
                          </div>
                        </div>
                      </div>

                   
                      <div class="col-lg-1">
                        <div class="row icon-demo-content-10 mb-3">
                          <label class="form-label" > {{ ' ' }}&nbsp;</label>
                          <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveListingBroker(idx)" v-if="broker.is_local != true">
                            <i class="uil-times font-size-4"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- end card Listing Brokers-->



                <!-- Selling Broker -->
                <div class="card border shadow-none mb-5" >
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          04A
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Selling Agent & Broker</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <b-button variant="primary" @click="onAddSellingBroker" class="col-3">Add Entry</b-button>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row" v-for="(broker, idx) in deal.selling_brokers" :key="'agent_' + idx + '_selling_broker_' + broker.temp_obj_id">
                     
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" >Agent-{{ broker.agent_name }}</label>
                          <AgentAutoComplete :ref="'agent_broker_name_'+broker.temp_obj_id" :broker_idx="idx" v-model="broker.agent_name" :readonly="broker.is_local" :init_data="{user_obj: {broker_index : idx, agent_type: 'SELLING_BROKER'}, agent_name : broker.agent_name, style_class: { 'is-invalid': $v.deal.selling_brokers.$each[idx].agent_name.$error } }" :show_brokerage="false" :search_type="'REGBV'" @onSelected="onSelectedBrokerAgent"
                            autocomplete="off" :class="{ 'is-invalid': true }" />
                            <div v-if="$v.deal.selling_brokers.$each[idx].agent_name.$error" class="invalid-feedback">
                              <span v-if="$v.deal.selling_brokers.$each[idx].agent_name.$error">This value is required.</span>
                            </div>
                
                        </div>
                      </div>
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label">Broker</label>
                          <input type="text" class="form-control" v-model="broker.brokerage" maxlength="80" :readonly="broker.is_local" :class="{ 'is-invalid': $v.deal.selling_brokers.$each[idx].brokerage.$error }" />
                          <div v-if="$v.deal.selling_brokers.$each[idx].brokerage.$error" class="invalid-feedback">
                            <span v-if="$v.deal.selling_brokers.$each[idx].brokerage.$error">This value is required.</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" >Commission</label>
                          <CurrencyInput v-model.lazy="broker.commission"   :ref="'agent_broker_comm_'+broker.temp_obj_id"  @input="other_comm_watcher" @blur="other_comm_watcher" :readonly="broker.is_local" :class="{ 'is-invalid': $v.deal.selling_brokers.$each[idx].commission.$error }"/>
                          <div v-if="$v.deal.selling_brokers.$each[idx].commission.$error" class="invalid-feedback">
                            <span v-if="$v.deal.selling_brokers.$each[idx].commission.$error">This value is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label" >Gst</label>
                          <CurrencyInput v-model.lazy="broker.gst"  :ref="'agent_broker_comm_gst_'+broker.temp_obj_id"  @input="other_comm_gst_watcher" @blur="other_comm_gst_watcher" :readonly="broker.is_local"  :class="{ 'is-invalid': $v.deal.selling_brokers.$each[idx].gst.$error }" />
                          <div v-if="$v.deal.selling_brokers.$each[idx].gst.$error" class="invalid-feedback">
                            <span v-if="$v.deal.selling_brokers.$each[idx].gst.$error">This value is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label" >Total</label>
                          <CurrencyInput v-model.lazy="broker.total"  :ref="'agent_broker_comm_total_'+broker.temp_obj_id"   readonly  />
                           
                        </div>
                      </div>

                   
                      <div class="col-lg-1">
                        <div class="row icon-demo-content-10 mb-3">
                          <label class="form-label" > {{ ' ' }}&nbsp;</label>
                          <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveSellingBroker(idx)" v-if="broker.is_local != true">
                            <i class="uil-times font-size-4"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- end card Agent & Commission-->



                <!--  Commission Total-->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          04C
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Commission</h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <div>
                      <div class="row gap-4">

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Listing Commission</label>
                            <CurrencyInput ref="listing_commission_ref"   readonly />
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Listing Commission Gst</label>
                            <CurrencyInput  ref="listing_commission_gst_ref" readonly  />
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Total Listing Commission</label>
                            <CurrencyInput   ref="listing_commission_total_ref" readonly/>
                          </div>
                        </div>

                      </div>
                      <div class="row gap-4">
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Selling Commisison</label>
                            <CurrencyInput ref="selling_commission_ref"   readonly />
                           
                          </div>

                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Selling Commisison Gst</label>
                            <CurrencyInput ref="selling_commission_gst_ref"  readonly  />
                           
                          </div>

                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Total Selling Commisison</label>
                            <CurrencyInput  ref="selling_commission_total_ref" readonly/>
                          </div>

                        </div>

                        

                      </div>
                    </div>
                  </div>
                </div>
                <!-- end card Commission -->
               


                <!-- Lawyer  -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          05
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Lawyer</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <div class="flex-grow-1 text-end align-items-right">
                      <b-button variant="primary" @click="onAddNewLawyer" class="col-3">Add Lawyer</b-button>
                    </div>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row" v-for="(lawyer, idx) in deal.lawyers" :key="'lawyer_'+idx+'_'+lawyer.id">
                      <div class="row">
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Lawyer {{ idx +1 }}</label>
                            <select class="form-control lawyer_choice" v-model="lawyer.type">
                              <option value="Buyer Lawyer" selected>Buyer Lawyer</option>
                              <option value="Seller Lawyer" >Seller Lawyer</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="mb-3">
                            <label class="form-label">Name</label>
                            <vue-bootstrap-typeahead v-model="searched_lawyer"  :value="lawyer.name"  :remoteMatch="true"
                              :data="lawyer_data" :serializer="s => s.name" :foramterDisplay="s => s.name"  :ref="'lawyer_name_'+idx"
                              @hit="onLawyerSelected($event, lawyer, idx)" autocomplete="off" />


                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Phone</label>
                            <input type="text" class="form-control"  v-mask="'(###) ###-####'" v-model="lawyer.phone" />
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Email</label>
                            <input type="text" class="form-control" v-model="lawyer.email" />
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label" >Email2</label>
                            <input type="text" class="form-control" v-model="lawyer.email2" />
                          </div>
                        </div>
                        <div class="col-lg-1">

                          <div class="row icon-demo-content-10 mb-3">
                            <label class="form-label" > {{ ' ' }}&nbsp;</label>
                            <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveLawyer(idx)">
                              <i class="uil-times font-size-4"></i>
                            </div>
                          </div>
                        </div>


                      </div>
                      <div class="row">
                        <div class="col-lg-1">
                          <div class="mb-3">
                            <label>Unit</label>
                            <input type="text" class="form-control" v-model="lawyer.unit" />
                          </div>
                        </div>
                        <div class="col-lg-10">
                          <div class="mb-3">
                            <label class="form-label" >Address</label>
                            <AddressAutoComplete :lawyer_index="idx" v-model="searched_property_address" :ref="'lawyer_address_'+idx"
                              @onSelected="onLawyerAddreessSelected" autocomplete="off" :init_data="{address: lawyer.address}"  />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <hr class="my-1" />
                      </div>

                    </div>
                   

                 
                  </div>
                </div>
                <!-- end card -->

              </div>

              <div class="row">
                <div class="col-md-12 d-flex align-items-right justify-content-sm-end">
                <div class="text-end d-flex flex-wrap gap-2">
                  <button type="button" class="btn btn-secondary w-sm" @click="$router.push({name : 'deal-overview', query: {deal_id : deal.order_deal_id}})" @keyup.enter.prevent=""> Cancel  </button>
                  <button type="submit" class="btn btn-primary w-sm" @keyup.enter.prevent=""> Submit Deal </button>
                </div>
              </div>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

